<template>
    <div class="d-flex flex-column px-4 py-4">
        <h2 class="mb-4 cursive-font">Add Virtual Assistant</h2>

        <v-card class="auth-mob-padding auth-mob-padding-2 box px-5 py-5">
            <div class="form-box">
                <v-form @submit.prevent>

                    <v-row style="margin-top: 1px">
                        <v-col>
                            <v-text-field outlined label="First name" v-model="state.first_name" placeholder="First name"
                                hide-details="auto" class="mb-6" autofocus required />
                        </v-col>

                        <v-col>
                            <v-text-field outlined label="Last name" v-model="state.last_name" placeholder="Last name"
                                hide-details="auto" class="mb-6" required />
                        </v-col>
                    </v-row>

                    <v-text-field outlined label="Username" placeholder="username" v-model="state.username"
                        hide-details="auto" class="mb-6" required />

                    <v-text-field outlined label="Email" placeholder="username@domain.com" v-model="state.email"
                        hide-details="auto" class="mb-6" required />

                    <v-text-field type="number" outlined label="Phone" placeholder="(000) 000-0000"
                        v-model="state.phone_number" hide-details="auto" class="mb-6" required />

                    <!-- <v-textarea outlined clear-icon="mdi-close-circle" label="Description"
                                                            v-model="state.va_description" placeholder="Type your bio or description" rows="4"
                                                            row-height="25" />

                                                        <v-textarea outlined clear-icon="mdi-close-circle" label="Skills (Separated by Comma ' , ')"
                                                            v-model="state.skills" placeholder="Type your skills here" rows="4" row-height="25" /> -->

                    <v-btn width="100%" large color="primary" :loading="isLoading" @click="addUser"> Submit </v-btn>
                </v-form>
            </div>
        </v-card>

        <v-snackbar :timeout="2000" :value="isSnackBarOpen" relative top :color="isError ? 'error' : 'success'" right>
            <p class="text-size">
                <strong>
                    {{ message }}
                </strong>
            </p>
        </v-snackbar>

    </div>
</template>

<script>
import { ref, reactive, computed } from "@vue/composition-api";
import axios from '@axios';

export default {
    setup() {
        const isSnackBarOpen = ref(false)
        const isLoading = ref(false)
        const isError = ref(false)
        const message = ref('')
        const state = reactive({
            type: 'va',
            first_name: '',
            last_name: '',
            username: '',
            email: '',
            phone_number: '',
            // va_description: '',
            // skills: ''
        })

        const vaData = computed(() => {
            return {
                type: 'va',
                first_name: state.first_name,
                last_name: state.last_name,
                username: state.username,
                email: state.email,
                phone_number: state.phone_number,
                // va_description: state.va_description,
                // skills: state.skills
            }
        })

        const resetForm = () => {
            state.first_name = ''
            state.last_name = ''
            state.username = ''
            state.email = ''
            state.phone_number = ''
            // state.va_description = ''
            // state.skills = ''
        }

        const addUser = async () => {
            try {
                isLoading.value = true
                const res = await axios.post('/add-user', vaData.value)
                if (res) {
                    isError.value = false
                    isLoading.value = false
                    message.value = 'Virtual Assistant Added Successfully.'
                    isSnackBarOpen.value = true
                    setTimeout(() => {
                        isSnackBarOpen.value = false
                    }, 2100)
                    resetForm()
                }
            } catch (err) {
                isError.value = true
                isLoading.value = false
                message.value = err.response.data.message
                isSnackBarOpen.value = true
                setTimeout(() => {
                    isSnackBarOpen.value = false
                    isError.value = false
                }, 2100)
                throw err
            }
        }

        return {
            isSnackBarOpen,
            isLoading,
            addUser,
            state,
            message,
            isError
        }
    }
}
</script>

<style scoped>
.text-size {
    font-size: 1.1rem;
}

.form-box {
    width: 40%;
}

@media (max-width:768px) {
    .form-box {
        width: 100%;
    }
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}
</style>